const replace = {
  changeSMID: 'Wijzig de CKW ID',
  reqSMID: 'CKW ID *',
  SMID: 'CKW ID',
  SMIDTolltip: 'De ID is een unieke identificatie van uw apparaat. Deze ID vind je op het toestel onder de QR-code.',
  enableSmId: 'CKW ID',
  smidLabel: 'CKW ID',
  confirmDeletingDesc: 'Bevestig het verwijderen van alle gegevens van deze gateway door de CKW ID in te voeren. Eenmaal bevestigd, kan dit proces niet meer worden teruggedraaid.',
  confirmDeletingError: 'De ID komt niet overeen met de CKW ID. De gateway wordt niet verwijderd.'
};

export default replace;
