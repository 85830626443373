/* eslint-disable react/no-this-in-sfc */
import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import i18n from '../../../i18n';
import { chartGridColor, phase1Color, phase2Color, phase3Color, ticksColor, getPlotLines, getPlotBands, generalChartOptions } from '../constants';

import '../index.scss';

const LoadManagementLineChart = ({
  houseFuse,
  zoomHandler,
  yAxisMin,
  yAxisMax,
  chartData,
  loading = false,
  timezone = 'UTC'
}) => {
  const plotLines = getPlotLines(houseFuse, true);
  const plotBands = useMemo(() => getPlotBands(houseFuse, [chartData.lineChart?.L1, chartData?.lineChart.L2, chartData?.lineChart.L3].flat().map((item = []) => item[1])), [chartData, houseFuse]);

  const ChartData = [
    {
      type: 'line',
      name: i18n.t('phase1'),
      data: cloneDeep(chartData?.lineChart.L1),
      color: phase1Color
    },
    {
      type: 'line',
      name: i18n.t('phase2'),
      data: cloneDeep(chartData?.lineChart.L2),
      color: phase2Color
    },
    {
      type: 'line',
      name: i18n.t('phase3'),
      data: cloneDeep(chartData?.lineChart.L3),
      color: phase3Color
    }
  ];

  return (
    <>
      {!loading ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...generalChartOptions,
            plotOptions: {
              series: {
                marker: {
                  symbol: 'circle',
                  radius: 4,
                  fillColor: 'rgb(255, 255, 255, 0.2)',
                  enabled: false,
                  states: { hover: { enabled: true } }
                }
              }
            },

            line: {
              states: {
                hover: { enabled: true },
                inactive: { enable: false, opacity: 1 }
              }
            },

            chart: {
              marginBottom: 50,
              zoomType: 'x',
              panning: true,
              pinchType: 'x',
              zooming: { mouseWheel: { enabled: false } },
              backgroundColor: 'transparent',
              style: { fontFamily: 'Gotham' },
              resetZoomButton: {
                theme: { display: 'none' },
                position: {
                  align: 'right',
                  verticalAlign: 'top',
                  x: 1110,
                  y: 10
                }
              }
            },

            time: {
              getTimezoneOffset(timestamp) {
                const timezoneOffset = -moment.tz(timestamp, timezone).utcOffset();
                return timezoneOffset;
              }
            },
            xAxis: {
              min: moment(chartData?.realTimeData?.from || chartData?.from).valueOf(),
              events: {
                setExtremes: zoomHandler
              },
              type: 'datetime',
              gridLineColor: chartGridColor,
              tickColor: '#D6D8D5',
              lineColor: '#D6D8D5',
              gridLineWidth: 1,
              crosshair: { snap: true, zIndex: 5, width: 2 },
              labels: {
                style: {
                  color: ticksColor,
                  fontSize: '16px',
                  fontWeight: '400'
                },
                tickWidth: 0,
                lineWidth: 0
              }
            },
            yAxis: {
              plotBands,
              plotLines,
              title: null,
              gridLineColor: chartGridColor,
              gridLineWidth: 1,
              labels: {
                enabled: false,
                labels: {
                  style: {
                    color: ticksColor,
                    fontSize: '16px',
                    fontWeight: '400'
                  }
                }
              },
              grid: {
                drawBorder: false,
                drawTicks: false,
                color() {
                  return chartGridColor;
                }
              },
              max: houseFuse >= yAxisMax ? Math.ceil((houseFuse + 10) / 10) * 10 : yAxisMax,
              min: houseFuse * -1 <= yAxisMin ? Math.ceil((houseFuse + 10) / 10) * -10 : yAxisMin
            },
            series: ChartData,
            lang: {
              noData: i18n.t('chartsNoData'),
              shortMonths: moment.locale !== 'en' && moment.monthsShort(),
              loading: i18n.t('load')
            }
          }}
          containerProps={{ className: 'line-chart' }}
        />
      ) : null}
      {loading ? (
        <div className="line-chart">
          <div className="loadingTextWithBG loading">
            {i18n.t('load')}
            ...
          </div>
        </div>
      ) : null}
    </>
  );
};

LoadManagementLineChart.propTypes = {
  houseFuse: PropTypes.number.isRequired,
  zoomHandler: PropTypes.func.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  yAxisMin: PropTypes.number.isRequired,
  chartData: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  timezone: PropTypes.string
};

export default memo(LoadManagementLineChart, (prevProps, nextProps) => prevProps.chartData?.realTimeData?.to === nextProps.chartData.realTimeData?.to
  && prevProps.chartData?.to === nextProps.chartData.to
  && prevProps.chartData?.interval === nextProps.chartData?.interval
  && prevProps.loading === nextProps.loading
  && prevProps.houseFuse === nextProps.houseFuse
  && prevProps.yAxisMax === nextProps.yAxisMax
  && prevProps.yAxisMin === nextProps.yAxisMin
  && prevProps.timezone === nextProps.timezone
);
